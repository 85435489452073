import { graphql } from 'gatsby'

import { Client } from './Client'

export default Client

export const query = graphql`
  fragment ClientFragment on ContentfulClient {
    name
    url
    logo {
      ... FullImageFragment
    }
  }
`
