import { graphql } from 'gatsby'

import { CaseStudy } from './CaseStudy'

export default CaseStudy

export const query = graphql`
  fragment CaseStudyFragment on ContentfulCaseStudy{
    headline
    lead {
      lead
    }
    client {
      ... ClientFragment
    }
    hideClient
    backgroundColor
    image {
      fluid(quality: 80, maxWidth: 600) {
        ... GatsbyContentfulFluid_withWebp
      }
      ... FluidImageFragment
    }
    hasDetail
    slug
  }
`
