import React from 'react'

import Client from '../../components/Client'
import Section from '../../layout/Section'

import styles from './clients-section.module.scss'

type Props = SectionItems<Client>

export const ClientsSection: React.FC<Props> = ({ headline, items }) =>
  <Section
    headline={headline}
    white
    topPadding='huge'
    bottomPadding='xlarge'
    className={styles.clientsSection}
  >
    <div className={styles.logos}>
      {items.map((client, index) => <Client key={`${client.name}-${index}`} {...client}/>)}
    </div>
  </Section>
