import React from 'react'

import Card from '../Card'

import styles from './client.module.scss'

type Props = Client

export const Client: React.FC<Props> = ({ logo, name, url }) => {
  const Wrapper = url ? LinkClient : React.Fragment

  return (
    <Wrapper url={url}>
      <Card flat className={url ? undefined : styles.logo}>
        <img className='no-margin-btm' src={logo.url} alt={logo.alt || name}/>
      </Card>
    </Wrapper>
  )
}

const LinkClient: React.FC<Pick<Client, 'url'>> = ({ children, url }) => (
  <a href={url} target='_blank' rel='noopener noreferrer' className={styles.logo}>{children}</a>
)
