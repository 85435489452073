import React from 'react'

import Contact from '../../components/Contact'
import Hero from '../../components/Hero'
import {
  extractCaseStudyPreviewSection,
  extractClientSection,
  extractServiceSection,
} from '../../contentful'
import { SectionType as SectionTypeEnum } from '../../enums'
import Layout from '../../layout/Layout'
import { CaseStudiesSection as CaseStudies } from './CaseStudiesSection'
import { ClientsSection as Clients } from './ClientsSection'
import { LeadSection } from './LeadSection'
import { ServicesSection as Services } from './ServicesSection'

interface Props {
  pageContext: PageContext
}

export const Home: React.FC<Props> = ({ pageContext }) => {
  const { clients, services, caseStudies, lead } = extractSections(pageContext.sections)

  return (
    <Layout pageContext={pageContext}>
      <Hero headline={pageContext.headline} subheadline={pageContext.subheadline}/>
      <LeadSection leadData={lead}/>
      <Services headline={services.headline} items={services.items}/>
      <Clients headline={clients.headline} items={clients.items}/>
      <CaseStudies headline={caseStudies.headline} items={caseStudies.items}/>
      <Contact/>
    </Layout>
  )
}

interface Sections {
  clients: SectionItems<Client>
  services: SectionItems<Service>
  caseStudies: SectionItems<CaseStudyPreview>
  lead?: SectionSource<LeadSource>
}

const extractSections = (sections: SectionSource[]): Sections => {
  const clientData = sections.find(section => section.type === SectionTypeEnum.Client)
  const serviceData = sections.find(section => section.type === SectionTypeEnum.Service)
  const caseStudyData = sections.find(section => section.type === SectionTypeEnum.CaseStudy)

  const leadSection = sections.find(section => section.type === SectionTypeEnum.Lead)

  return {
    clients: extractClientSection(clientData as SectionItemsSource<ClientSource>),
    services: extractServiceSection(serviceData as SectionItemsSource<ServiceSource>),
    caseStudies: extractCaseStudyPreviewSection(
      caseStudyData as SectionItemsSource<CaseStudyPreviewSource>),
    lead: leadSection as SectionSource<LeadSource>,
  }
}
