import React from 'react'

import Card from '../Card'
import { CaseStudyIllustration as Illustration } from './CaseStudyIllustration'
import { CaseStudyInfo as Info } from './CaseStudyInfo'

import styles from './case-study.module.scss'

interface Props extends CaseStudyPreview {
  extraShadow?: boolean
}

export const CaseStudy: React.FC<Props> = ({
  client,
  headline,
  lead,
  image,
  backgroundColor,
  slug,
  hasDetail,
  hideClient,
  extraShadow = false,
}) => (
  <div className={styles.caseStudyContainer}>
    <Card className={`${styles.caseStudy} grid-x`} extraShadow={extraShadow} flat>
      <Info
        img={client.logo}
        headline={headline}
        lead={lead}
        hasDetail={hasDetail}
        slug={slug}
        hideClient={hideClient}
      />
      <Illustration illustration={image} bgColor={backgroundColor}/>
    </Card>
    <div className={styles.whiteBackground}/>
  </div>
)
