import React from 'react'

import CaseStudy from '../../components/CaseStudy'
import Section from '../../layout/Section'

import styles from './case-studies-section.module.scss'

type Props = SectionItemsSource<CaseStudyPreview>

export const CaseStudiesSection: React.FC<Props> = ({ headline, items }) =>
  <Section headline={headline} addons={addons} dark bottomPadding='large'>
    <div className={styles.caseStudies}>
      {items.map((item, idx) => <CaseStudy key={idx} {...item}/>)}
    </div>
  </Section>

const addons = {
  top: {
    addon: <img
      src='/images/illustrations/planet4.svg'
      alt='Planet illustration decorating top of case studies section'/>,
    top: '-46px',
  },
  freeForm: {
    addon: <img
      src='/images/illustrations/planet2.svg'
      alt='Planet illustration decorating bottom of case studies section'/>,
    bottom: '-56px',
    left: '0',
  },
}
