import React, { useMemo } from 'react'

import { Link } from 'gatsby'

import styles from './case-study.module.scss'

const linkText = 'Read more ->'

interface Props {
  img: FullImage
  headline: string
  lead: string
  hasDetail: boolean
  slug: string
  hideClient: boolean
}

export const CaseStudyInfo: React.FC<Props> = ({
  img,
  headline,
  lead,
  hasDetail,
  slug,
  hideClient,
}) => {
  const detailLink = useMemo(() => {
    const className = `btn btn--red btn--small ${!hasDetail ? 'btn--disabled' : ''}`
    return hasDetail ? (
      <Link to={`/case-study/${slug}/`} className={className}>{linkText}</Link>
    ) : (
      <span className={className}>{linkText}</span>
    )
  }, [hasDetail, slug])

  return (
    <div className={`${styles.info} small-12 small-order-2 large-6 large-order-1`}>
      {!hideClient && (
        <div className='show-for-large'>
          <img src={img.url} alt={img.alt || `${slug} case study`}/>
        </div>
      )}
      <div>
        <h3>{headline}</h3>
        <p>{lead}</p>
      </div>
      <div>
        {detailLink}
      </div>
    </div>
  )
}
