import Img from 'gatsby-image'
import React from 'react'

import styles from './case-study.module.scss'

const outerClassName = [
  styles.illustrationContainer,
  'small-12',
  'small-order-1',
  'large-6',
  'large-order-2',
  'grid-x',
].join(' ')

const innerClassName = [
  styles.illustration,
  'small-10',
  'small-offset-1',
  'medium-8',
  'medium-offset-2',
  'large-12',
  'large-offset-0',
].join(' ')

interface Props {
  illustration: FluidImage
  bgColor: string
}

export const CaseStudyIllustration: React.FC<Props> = ({ illustration, bgColor }) => (
  <div className={outerClassName}>
    <div style={{ backgroundColor: `#${bgColor}` }} className={styles.illustrationBackground}/>
    <div className={innerClassName}>
      {illustration.fluid && (
        <Img fluid={illustration.fluid} alt={illustration.alt || 'Case study illustration'}/>
      )}
    </div>
  </div>
)
